



































import Vue from "vue";
export default Vue.extend<any, any, any, any>({
  name: "ListItem",
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    title(): string {
      const matches = this.item.title.match(/\b(\w)/g);

      return matches.join("");
    },
  },
  methods: {
    click(): void {
      if (this.item.toggleMore) {
        this.$store.dispatch("toggleMini", true);
        this.$store.dispatch("expandSidebar", true);
      }
    },
  },
});
